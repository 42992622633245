import { getCurrentUser, useFirebaseAuth } from "vuefire";
import type { User } from "~/server/entities/user";

export default defineNuxtRouteMiddleware(async (to, _from) => {
  if (import.meta.server) return;

  useFirebaseAuth(); // only exists on client side
  const currentUser = await getCurrentUser();

  let userData: User;

  if (currentUser) {
    userData = await $fetch<User>("/api/v1/users", {
      method: "PUT",
      body: {
        id: currentUser.uid,
        email:
          currentUser.email ??
          `anon.${currentUser.uid.substring(0, 6)}@gymblaze.id`,
        name:
          currentUser.displayName ??
          `Anonymous #${currentUser.uid.substring(0, 6)}`,
      },
    });

    if (!userData?.phone && to.path !== "/phone-number") {
      const params = new URLSearchParams({
        redirect:
          to.query.redirect?.toString() || encodeURIComponent(to.fullPath),
      });
      const query = params ? "?" + params : "";
      // Need to use location.replace to ignore hydration error
      return window.location.replace(`/phone-number${query}`);
    }
    if (
      to.path === "/login" ||
      (to.path === "/phone-number" && userData?.phone)
    ) {
      // Need to use location.replace to ignore hydration error
      return window.location.replace(
        decodeURIComponent(to.query?.redirect?.toString() ?? "/locations"),
      );
    }
  } else {
    if (to.path === "/login") return;
    const params = new URLSearchParams({
      redirect:
        to.query.redirect?.toString() || encodeURIComponent(to.fullPath),
    });
    const query = params ? "?" + params : "";
    // Need to use location.replace to ignore hydration error
    window.location.replace(`/login${query}`);
  }
});
